<template>
  <div class="price-list-detail-view">
    <v-row>
      <v-col class="hidden-sm-and-down" cols="0" md="3">
        <SsoUserMenu v-if="isSsoAuthenticated" class="menu-navigator" />
        <v-divider class="my-5"></v-divider>
        <MenuNavigator class="menu-navigator" />
      </v-col>
      <v-col cols="12" md="9">
        <ProfileTitle :category="category" />

        <PriceListDetail />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import MenuNavigator from "@/components/profile/MenuNavigator";
import ProfileTitle from "@/components/profile/ProfileTitle";
import PriceListDetail from "@/components/priceList/PriceListDetail.vue";
import { mapGetters } from "vuex";

import categoryMixin from "~/mixins/category";
import SsoUserMenu from "@/components/profile/SsoUserMenu.vue";

export default {
  name: "PriceListDetailView",
  mixins: [categoryMixin],
  components: {
    MenuNavigator,
    ProfileTitle,
    PriceListDetail,
    SsoUserMenu
  },
  computed: {
    ...mapGetters({
      isSsoAuthenticated: "cart/isSsoAuthenticated"
    })
  }
};
</script>
